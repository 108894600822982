<template>
    <div id="mapid"></div>
</template>

<script>
import { urlAPI } from '../variables.js'
import axios from 'axios'
import terminator from '@joergdietrich/leaflet.terminator'
import AutoGraticule from 'leaflet-auto-graticule'

export default {
  name: 'map-strateole',
  props: {
    flights: Array,
    previ: String,
    color: String
  },
  data () {
    return {
      flights_ait: [],
      flights_inflight: [],
      loading: true,
      errored: false,
      mymap: null,
      style: null,
      coltraj: ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    }
  },
  methods: {
    formatNumber: function (d) {
      let res = ''
      let t = Number(d)
      if (t && !isNaN(t)) { res = t.toFixed(2) }
      return res
    },
    updateMap: function (newVal) {
      // on nettoie la carte
      this.mymap.eachLayer(function (layer) {
        if (layer.toGeoJSON) {
          layer.remove()
        }
      })
      terminator().addTo(this.mymap)

      L.polyline([[0, -180], [0, 180]], this.style).addTo(this.mymap)
      this.mymap.scrollWheelZoom.disable()

      var LeafIcon = L.Icon.extend({
        options: {}
      })
      let starIcon = new LeafIcon({ iconUrl: '/img-map/seychelles.png', iconSize: [32, 32], iconAnchor: [16, 32] })
      let popstar = '<strong>Strateole2 launch area</strong>'
      L.marker([-4.679247, 55.530423], { icon: starIcon }).bindPopup(popstar).addTo(this.mymap)

      // on vide les tableaux des vols
      this.flights_ait = []
      this.flights_inflight = []

      // tri tableau des vols
      var byFlightID = newVal.slice(0)
      byFlightID.sort(function (a, b) {
        var x = a.flight_id.toLowerCase()
        var y = b.flight_id.toLowerCase()
        return x < y ? -1 : x > y ? 1 : 0
      })
      newVal = byFlightID

      var ic = 0
      if (this.color && this.color != '') {
        ic = parseInt(this.color)
      }

      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i].is_flying) {
          var blueIcon = new LeafIcon({ iconUrl: '/img-map/marker-icon-blue.png', iconSize: [24, 24], iconAnchor: [3, 24] })
          // var blueIcon = new LeafIcon({ iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + this.coltraj[ic].substring(1) + '&chf=a,s,ee00FFFF', iconSize: [21, 34], iconAnchor: [11, 34] })
          if (newVal[i].flight_status === 'Terminated') {
            blueIcon = new LeafIcon({ iconUrl: '/img-map/end.png', iconSize: [24, 24], iconAnchor: [3, 24] })
            // blueIcon = new LeafIcon({ iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_xpin_icon&chld=pin_star|flag|' + this.coltraj[ic].substring(1) + '|' + this.coltraj[ic].substring(1) + '&chf=a,s,ee00FFFF', iconSize: [21, 34], iconAnchor: [11, 34] })
          }
          this.flights_inflight.push(newVal[i])
          try {
            if (newVal[i].euros !== null && newVal[i].euros.tsen !== null) {
              let popu = '<h2>' + newVal[i].flight_id + '</h2>Air temperature: <strong>' + this.formatNumber(newVal[i].euros.tsen.temperature_thermistance_C) + '°C</strong><br />Air pressure: <strong>' + this.formatNumber(newVal[i].euros.tsen.pressure) + ' hPa</strong>'
              let pos = L.marker([Number(newVal[i].euros.tsen.latitude), Number(newVal[i].euros.tsen.longitude)], { icon: blueIcon }).bindPopup(popu).addTo(this.mymap)
              if (newVal.length === 1) { this.mymap.setView([Number(newVal[i].euros.tsen.latitude), Number(newVal[i].euros.tsen.longitude)], 3) }

              let indiceCouleur = ic
              let term = false
              if (newVal[i].flight_status === 'Terminated') { term = true }
              var instance = axios.create({
                // headers: {'Authorization': 'ApiKey ' + sessionStorage.getItem("username") + ':' + sessionStorage.getItem("csrftoken")}
              })
              // https://observations.ipsl.fr/aeris/s2dac/qlooks/S2DAC/TRAJ/BALLOON/ST2_C1_51_TTL5.geojson
              instance
                .get(urlAPI + 'tmtc/get_graph/' + newVal[i].flight_id + '/Flight/' + newVal[i].flight_id + '.geojson.gz', [indiceCouleur, term])
                .then(response => {
                  /* let w = 3
                  let o = 1
                  if (term) {
                    w = 0.2
                    o = 0.8
                  } */
                  var geojsonMarkerOptions = {
                    weight: 4,
                    opacity: 1,
                    radius: 2,
                    fillOpacity: 1,
                    fillColor: '#FFFFFF',
                    color: this.coltraj[indiceCouleur]
                  }

                  var datalayer = L.geoJson(response.data,
                    {
                      pointToLayer: function (feature, latlng) {
                        return L.circleMarker(latlng, geojsonMarkerOptions)
                      },
                      filter: function (feature, layer) {
                        if (feature.type !== 'Feature') { return false } else {
                          // var keys = Object.keys(feature.coordinates);
                          // if ((feature.geometry.type === 'Point' && feature.geometry.coordinates[2] === '65535.0') || (feature.geometry.type === 'LineString' && newVal[i].flight_id.indexOf('_C0') > 0)) { return false } else { return true }
                          if ((feature.geometry.type === 'Point' && feature.geometry.coordinates[2] === '65535.0') || (feature.geometry.type === 'LineString')) { return false } else { return true }
                          // if ((feature.geometry.type === 'Point' && feature.geometry.coordinates[2] === '65535.0')) { return false } else { return true }
                        }
                      },
                      onEachFeature: function (feature, featureLayer) {
                        if (feature.type === 'Feature' && feature.properties) {
                          var keys = Object.keys(feature.properties)
                          var txt = ''
                          for (var i = 0; i < keys.length; i++) {
                            if (i === 0) { txt = '<strong>' + feature.properties[keys[i]] + '</strong>' } else { txt += '<br />' + keys[i] + ': ' + feature.properties[keys[i]] }
                          }

                          if (feature.geometry.type === 'Point') {
                            featureLayer.bindPopup(txt)
                            featureLayer.on('mouseover', function (e) {
                              this.openPopup()
                            })

                            featureLayer.on('mouseout', function (e) {
                              this.closePopup()
                            })
                          }
                        }
                      }
                    })

                  datalayer.setStyle({ color: this.coltraj[indiceCouleur], opacity: 0.5 })
                  datalayer.addTo(this.mymap)
                  // this.mymap.fitBounds(datalayer.getBounds())
                })
                .catch(error => {
                  console.log('geojson : ' + error)
                })
                .finally()

              if (this.previ == 'true' && newVal[i].flight_status != 'Terminated') {
                instance
                  .get('https://observations.ipsl.fr/aeris/s2dac/qlooks/S2DAC/TRAJ/BALLOON/' + newVal[i].flight_id + '.geojson', [indiceCouleur, term])
                  .then(response => {
                    var geojsonMarkerOptions = {
                      // radius: 800,
                      weight: 4,
                      opacity: 1,
                      radius: 2,
                      fillOpacity: 1,
                      fillColor: '#AAA',
                      color: '#AAA'
                      // fillColor: this.coltraj[indiceCouleur],
                      // color: this.coltraj[indiceCouleur]
                    }

                    var datalayer = L.geoJson(response.data,
                      {
                        pointToLayer: function (feature, latlng) {
                          return L.circleMarker(latlng, geojsonMarkerOptions)
                        },
                        filter: function (feature, layer) {
                          if (feature.type !== 'Feature') { return false } else {
                            // var keys = Object.keys(feature.coordinates);
                            if ((feature.geometry.type === 'Point' && feature.geometry.coordinates[2] === '65535.0') || (feature.geometry.type === 'LineString')) { return false } else { return true }
                            // if ((feature.geometry.type === 'Point' && feature.geometry.coordinates[2] === '65535.0')) { return false } else { return true }
                          }
                        },
                        onEachFeature: function (feature, featureLayer) {
                          if (feature.type === 'Feature' && feature.properties) {
                            var keys = Object.keys(feature.properties)
                            var txt = ''
                            for (var i = 0; i < keys.length; i++) {
                              if (i === 0) { txt = '<strong>' + feature.properties[keys[i]] + '</strong>' } else { txt += '<br />' + keys[i] + ': ' + feature.properties[keys[i]] }
                            }
                            if (feature.geometry.type === 'Point') {
                              featureLayer.bindPopup(txt)
                              featureLayer.on('mouseover', function (e) {
                                this.openPopup()
                              })

                              featureLayer.on('mouseout', function (e) {
                                this.closePopup()
                              })
                            }
                          }
                        }
                      })

                    datalayer.setStyle({ opacity: 0.5 })
                    datalayer.addTo(this.mymap)
                    // this.mymap.fitBounds(datalayer.getBounds())
                  })
                  .catch(error => {
                    console.log('geojson : ' + error)
                  })
                  .finally()
              }
              ic++
            }
          } catch (error) {
            console.error(error)
          }
        } else { this.flights_ait.push(newVal[i]) }
      }
    }
  },
  watch: {
    flights: function (newVal, oldVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.updateMap(newVal)
    }
  },
  mounted () {
    this.mymap = L.map('mapid', { zoomSnap: 0.5, zoomDelta: 0.5 }).setView([0, 0], 3)
    this.style = {
      color: 'lightgrey',
      weight: 1,
      opacity: 0.5,
      smoothFactor: 1
    }

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZG91ZGFpZXYiLCJhIjoiY2syMzhlYnpzMDFpbjNucDltaTIzb2FneSJ9.DtU_NDpOdx1SJ04P8ueJcw', {
      maxZoom: 18,
      noWrap: true,
      attribution: '',
      id: 'mapbox/outdoors-v11'
      // id: 'mapbox/satellite-streets-v11'
      // id: 'mapbox/streets-v11'
      // id: 'mapbox/light-v10'
    }).addTo(this.mymap)
    L.control.scale().addTo(this.mymap)

    if (this.flights) { this.updateMap(this.flights) }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mapid { height: 300px; }
</style>
