// export const urlWEB = 'https://webstr2.lmd.polytechnique.fr'
// export const urlAPI = 'https://webstr2.lmd.polytechnique.fr/api/v2/'

export const urlWEB = 'https://webstr2.ipsl.polytechnique.fr'
export const urlAPI = 'https://webstr2.ipsl.polytechnique.fr/api/v2/'

// export const urlWEB = 'https://webstr2test.lmd.polytechnique.fr/'
// export const urlAPI = 'https://webstr2test.lmd.polytechnique.fr/api/v2/'

// export const urlWEB = 'http://webstr2-testing.ipsl.polytechnique.fr/'
// export const urlAPI = 'http://webstr2-testing.ipsl.polytechnique.fr:8000/api/v2/'
