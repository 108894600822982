/* eslint-disable eqeqeq */
<template>
<div class="table-responsive" style="padding:10px 30px 0px 30px">
  <h2>Strateole 2 flights</h2>
<div v-if="flights_inflight.length>0">
  <table class="table table-striped" style="font-size: 0.9rem;">
    <thead>
      <tr>
        <th>Flight</th>
        <th>Type</th>
        <th>Phase</th>
        <th>Lat</th>
        <th>Lon</th>
        <th>Alt</th>
        <th>Launch (UT)</th>
        <th>Termination (UT)</th>
        <th>Duration</th>
        <th v-if="rightConnected">Gondola</th>
        <th colspan="2" v-if="rightConnected">Latest Iridium Call (UT)</th>
        <th v-if="rightConnected">Status</th>
        <th v-if="rightEmergency">Emergency</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th v-if="rightConnected"></th>
        <th v-if="rightConnected">Date</th>
        <th v-if="rightConnected">Duration</th>
        <th v-if="rightConnected"></th>
        <th v-if="rightEmergency">actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="entry in flights_inflight" v-bind:key="entry.flight_id">
        <td v-if="rightConnected">
          <strong><router-link :to="{ name: 'flight', params: { id: entry.id, color: entry.color} }" class="nav-link"><img :src="entry.img" style="height:20px" v-if="entry.img" />&nbsp;{{ entry.flight_id   }}</router-link></strong>
        </td>
        <td v-else>
          <strong><img :src="entry.img" style="height:20px" v-if="entry.img" /> {{ entry.flight_id }}</strong>
        </td>
        <td data-toggle="tooltip" data-placement="top" title="Configuration type">{{ entry.config_type }}</td>
        <td data-toggle="tooltip" data-placement="top" title="">
          <v-chip class="ma-2" small v-if="entry.flight_status=='In Flight'" style="color:white; background-color:#4caf50; margin: 2px 0px 2px 0px">
            {{ entry.flight_status }}
          </v-chip>
          <v-chip class="ma-2" small v-else style="margin: 2px 0px 2px 0px">
            {{ entry.flight_status }}
          </v-chip>
        </td>
        <td data-toggle="tooltip" data-placement="top" title="" v-if="entry.euros !== null && entry.euros.tsen !== null">{{ entry.euros.tsen.latitude }}</td>
        <td v-else></td>
        <td data-toggle="tooltip" data-placement="top" title="" v-if="entry.euros !== null && entry.euros.tsen !== null">{{ entry.euros.tsen.longitude }}</td>
        <td v-else></td>
        <td data-toggle="tooltip" data-placement="top" title="" v-if="entry.euros !== null && entry.euros.tsen !== null">{{ formatAlt(entry.euros.tsen.altitude) }}</td>
        <td v-else></td>
        <td data-toggle="tooltip" data-placement="top" title="">{{ formatDate(entry.release_date) }}</td>
        <td data-toggle="tooltip" data-placement="top" title="">{{ formatDate(entry.end_date) }}</td>
        <td>{{ entry.test }}</td>
        <td v-if="rightConnected">
          <div v-if="entry.related_gondola_id.length > 0"><strong><router-link :to="{ name: 'flight', params: { id: entry.id + 'z' } }" class="nav-link">{{ entry.related_gondola_id }}</router-link></strong></div>
        </td>
        <td v-if="rightConnected" data-toggle="tooltip" data-placement="top" title="">{{ formatDate(entry.last_session.date) }}</td>
        <td v-if="rightConnected" data-toggle="tooltip" data-placement="top" title="">{{ entry.last_session.duration }}</td>
        <td v-if="rightConnected">
          <v-chip class="ma-2" small v-if="entry.sbd_status=='OK'" style="color:white; background-color:#4caf50; margin: 2px 0px 2px 0px">
            {{ entry.sbd_status }}
          </v-chip>
          <v-chip class="ma-2" small v-else style="color:white; background-color:#DC143C; margin: 2px 0px 2px 0px">
            <strong>{{ entry.sbd_status }}:&nbsp;</strong> {{ entry.sbd_message }}
          </v-chip>
        </td>
        <td v-if="rightEmergency">
          <router-link :to="{ name: 'emergency', params: { id: entry.id } }" class="nav-link">
            <span v-if="entry.flight_status == 'Pre-terminated' || entry.flight_status == 'Terminated'" title="" class="glyphicon glyphicon-eye-open"  />
            <span v-else title="" class="glyphicon glyphicon-off" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  <div v-else>
    <i>No flights</i>
    </div>
    <div v-if="flights_ait.length>0">
  <h2 v-if="rightConnected">Gondolas in preparation (AIT)</h2>
  <table class="table table-striped" v-if="rightConnected" style="font-size: 0.9rem;">
    <thead>
      <tr>
        <th>Flight</th>
        <th>Type</th>
        <th>Phase</th>
        <th>Gondola</th>
        <th colspan="2">Latest Iridium Call (UT)</th>
        <th>Status</th>
        <th v-if="rightEmergency">Emergency</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th>Date</th>
        <th>Duration</th>
        <th></th>
        <th v-if="rightEmergency">actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="entry in flights_ait" v-bind:key="entry.id">
        <td>
          <strong><router-link :to="{ name: 'flight', params: { id: entry.id} }" class="nav-link">{{ entry.flight_id }}</router-link></strong>
        </td>
        <td data-toggle="tooltip" data-placement="top" title="Configuration type">{{ entry.config_type }}</td>
        <td data-toggle="tooltip" data-placement="top" title="">{{ entry.flight_status }}</td>
        <td>
          <div v-if="entry.related_gondola_id.length > 0"><strong><router-link :to="{ name: 'flight', params: { id: entry.id + 'z'   } }" class="nav-link">{{ entry.related_gondola_id }}</router-link></strong></div>
        </td>
        <td data-toggle="tooltip" data-placement="top" title="">{{ formatDate(entry.last_session.date) }}</td>
        <td data-toggle="tooltip" data-placement="top" title="">{{ entry.last_session.duration }}</td>
        <td>
          <v-chip class="ma-2" small v-if="entry.sbd_status=='OK'" style="color:white; background-color:#4caf50; margin: 2px 0px 2px 0px">
            {{ entry.sbd_status }}
          </v-chip>
          <v-chip class="ma-2" small v-else style="color:white; background-color:#DC143C; margin: 2px 0px 2px 0px">
            <strong>{{ entry.sbd_status }}:&nbsp;</strong> {{ entry.sbd_message }}
          </v-chip>
        </td>
        <td v-if="rightEmergency">
          <router-link :to="{ name: 'emergency', params: { id: entry.id } }" class="nav-link">
            <span v-if="entry.flight_status == 'Pre-terminated' || entry.flight_status == 'Terminated'" title="" class="glyphicon glyphicon-eye-open"  />
            <span v-else title="" class="glyphicon glyphicon-off"   />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  <div v-else>
    <i>No flights in preparation.</i>
    </div>
</div>
</template>

<script>
import { getRight } from '../helpers.js'

export default {
  name: 'strateole',
  props: {
    flights: Array
  },
  data () {
    return {
      flights_ait: [],
      flights_inflight: [],
      duration: '',
      loading: true,
      errored: false,
      rightEmergency: false,
      rightConnected: false,
      coltraj: ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    }
  },
  methods: {
    countUpFromTime: function (countFrom, endDate) {
      // countFrom = new Date(countFrom).getTime();
      var now = new Date()
      if (endDate && endDate !== '') {
        // alert("e"+endDate+"e");
        now = new Date(endDate)
      }

      var countFrom2 = new Date(countFrom + '.000Z')
      var timeDifference = (now - countFrom2)

      var secondsInADay = 60 * 60 * 1000 * 24
      var secondsInAHour = 60 * 60 * 1000

      let days = Math.floor(timeDifference / (secondsInADay) * 1)
      let hours = Math.floor((timeDifference % (secondsInADay)) / (secondsInAHour) * 1)
      let mins = Math.floor(((timeDifference % (secondsInADay)) % (secondsInAHour)) / (60 * 1000) * 1)
      // let secs = Math.floor((((timeDifference % (secondsInADay)) % (secondsInAHour)) % (60 * 1000)) / 1000 * 1)

      return days + ' days, ' + hours + ' hours, ' + mins + ' mins'
    },
    formatDate: function (d) {
      let res = ''
      if (d && d.length > 8) { res = d.substr(0, 16) }

      return res.replace('T', ' ')
    },
    formatAlt: function (d) {
      let res = ''
      let t = Number(d)
      if (t && !isNaN(t)) {
        res = t.toFixed(0)
      }

      return res
    }
  },
  watch: {
    flights: function (newVal, oldVal) { // watch it
      this.flights_ait = []
      this.flights_inflight = []
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      var byFlightID = newVal.slice(0)
      /* byFlightID.sort(function (a, b) {
        var x = a.config_type.toLowerCase() + a.flight_id.toLowerCase()
        var y = b.config_type.toLowerCase() + b.flight_id.toLowerCase()
        return x < y ? -1 : x > y ? 1 : 0
      }) */
      byFlightID.sort(function (a, b) {
        var x = a.flight_id.toLowerCase()
        var y = b.flight_id.toLowerCase()
        return x < y ? -1 : x > y ? 1 : 0
      })
      newVal = byFlightID

      let ivol = 0
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i].flight_status !== 'Hidden') {
          if (newVal[i].is_flying) {
            if (newVal[i].release_date && newVal[i].release_date !== '') { newVal[i].test = this.countUpFromTime(newVal[i].release_date, newVal[i].end_date) }
            newVal[i].img = '/img-map/marker-icon-blue.png'
            // newVal[i].img = 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + this.coltraj[ivol].substring(1) + '&chf=a,s,ee00FFFF'
            newVal[i].color = ivol
            this.flights_inflight.push(newVal[i])
            ivol++
          } else { this.flights_ait.push(newVal[i]) }
        }
      }
    }
  },
  mounted () {
    this.rightEmergency = getRight('suivi_nacelle')
    this.rightConnected = getRight('connected')
  }
}
</script>

<style scoped>
</style>
