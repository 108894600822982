<template>
  <div class="home">
    <div class="row">
      <div class="col-sm-3">
        &nbsp;
      </div>

      <div class="col-sm-6">
        <h1 class="icon-stl ">
          <img class="logo" src="../assets/images/logo.png" width="100" height="100" alt="logo Stratéole2"/>
          <span class="sr-only">Logo Stratéole 2</span>
        </h1>
        <p class="accroche" style="font-size: 0.9rem;">Long-duration balloon flights across the tropics to study atmospheric dynamics and composition</p>
        <v-row align="center" style="padding:0px">
          <v-col
            cols="5"
            style="text-align:right;padding:8px 5px 0px 0px;margin:0px"
          >Select a campaign</v-col>
          <v-col
            cols="7"
            style="margin:0px; padding:0px"
          >
            <v-select
              :items="campaigns"
                  v-model="selCamp"
                  dense
                  solo
                  style="width:340px; margin:0px; padding:0px; z-index:10000"
                  @change="listFlights"
                >
            </v-select>
          </v-col>
        </v-row>
        <span style="text-align: center">
        </span>
      </div>

      <div class="col-sm-3">
        <div class="alert alert-primary" style="margin:10px 5px 0px 0px;font-size: 0.9rem;" role="alert"  v-if="rightConnected">
          <h5>Zephyr Operations Manager</h5>
          <strong>Call {{this.rdo_contact.rdoz_name}}: {{this.rdo_contact.rdoz_phone}}</strong><br />If no answer, call {{this.rdo_contact.rdos_name}}: {{this.rdo_contact.rdos_phone}}<br /><strong>Email: <a href="mailto:str2ccmz@ipsl.polytechnique.fr">str2ccmz@ipsl.polytechnique.fr</a></strong>
        </div>
      </div>
    </div>

    <MainMap :flights="this.flights" previ="false"  /><br />
    <Flights :flights="this.flights" />
  </div>
</template>

<script>
import { urlAPI } from '../variables.js'
import { getRight } from '../helpers.js'
import axios from 'axios'
// @ is an alias to /src
import MainMap from '@/components/MainMap.vue'
import Flights from '@/components/Flights.vue'

export default {
  name: 'home',
  components: {
    MainMap,
    Flights
  },
  data () {
    return {
      flights: null,
      campaigns: [],
      selCamp: null,
      loading: true,
      errored: false,
      rightConnected: false,
      rdo_contact: []
    }
  },
  methods: {
    listCampaigns: function () {
      axios
        .get(urlAPI + 'campaign/')
        .then(response => {
          for (let i = 0; i < response.data.objects.length; i++) {
            let tmp = response.data.objects[i].label + ' - ' + response.data.objects[i].place 
            this.campaigns.push({ value: response.data.objects[i].label, text: tmp })
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    listFlights: function () {
      this.flights = []
      this.$store.commit('updateCampaign', this.selCamp)
      let param = ''
      if (this.selCamp != '') {
        param = this.selCamp + '/'
      }
      axios
        .get(urlAPI + 'chains_of_flight/' + param)
        .then(response => {
          if (response.data.objects) { this.flights = response.data.objects } else { this.flights = response.data }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    getRDO: function () {
      this.loading = true
      this.errored = false
      var instance = axios.create({
        headers: { 'Authorization': 'ApiKey ' + sessionStorage.getItem('username') + ':' + sessionStorage.getItem('csrftoken') }
      })
      instance
        .get(urlAPI + 'rdo_contact/')
        .then(response => {
          this.rdo_contact = []
          this.rdo_contact = response.data.objects[0]
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    if (this.$store.getters['getCampaign']) {
      this.selCamp = this.$store.getters['getCampaign']
    } else {
      this.selCamp = 'C1'
      this.$store.commit('updateCampaign', this.selCamp)
    }

    this.$store.commit('updateFlight', null)
    this.rightConnected = getRight('connected')
    this.listCampaigns()
    this.listFlights()
    if (this.rightConnected) { this.getRDO() }
  }
}
</script>
