import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    flight: null,
    campaign: null
  },
  mutations: {
    updateFlight (state, flight) {
      state.flight = flight
    },
    updateCampaign (state, campaign) {
      state.campaign = campaign
    }
  },
  actions: {

  },
  getters: {
    getFlight (state) {
      return state.flight
    },
    getCampaign (state) {
      return state.campaign
    }
  }
})
