function isInGroup (group, groups) {
  let res = false
  if (groups.indexOf(group) > -1) { res = true }
  return res
}

function isPartInGroup (group, groups) {
  let res = false
  for (let i = 0; i < groups.length; i++) {
    if (groups[i].indexOf(group) > -1) { res = true }
  }
  return res
}

export function getRight (actionTitle) {
  let res = false
  if (sessionStorage.getItem('groups')) {
    let groups = sessionStorage.getItem('groups')

    var g = JSON.parse(groups)

    if (actionTitle === 'connected') {
      res = true
    }

    if (actionTitle === 'emergency') {
      res = isInGroup('obcz_ait_tc_submit', g)
      if (!res) { res = isInGroup('obcz_flt_tc_submit', g) }
    }

    if (actionTitle === 'suivi_nacelle') {
      res = isInGroup('rdo_suivi_nacelle', g)
    }

    /*
        if (actionTitle === "boldair_tc_submit") {
            res = isInGroup("boldair_tc_submit", g);
        }
*/

    // OBCZ
    if (actionTitle === 'obcz_logs_read') {
      res = isInGroup('active', g)
    }

    if (actionTitle === 'obcz_tm_read') {
      res = isInGroup('obcz_tm_read', g)
    }

    if (actionTitle === 'obcz_tc_read') {
      res = isInGroup('obcz_tc_read', g)
    }

    if (actionTitle === 'obcz_tc_submit') {
      res = isInGroup('obcz_ait_tc_submit', g)
      if (!res) { res = isInGroup('obcz_flt_tc_submit', g) }
    }

    if (actionTitle === 'obcz_ait_tc_submit') {
      res = isInGroup('obcz_ait_tc_submit', g)
    }

    if (actionTitle === 'obcz_flt_tc_submit') {
      res = isInGroup('obcz_flt_tc_submit', g)
    }

    // LOAC
    if (actionTitle === 'tc-loac') {
      res = isInGroup('loac_tmtc_read', g)
    }
    if (actionTitle === 'tc-loac-submit') {
      res = isInGroup('loac_tc_submit', g)
    }
    if (actionTitle === 'tm-loac') {
      res = isInGroup('loac_tmtc_read', g)
    }

    // SAWFPHY
    if (actionTitle === 'tc-sawfphy') {
      res = isInGroup('sawfphy_tmtc_read', g)
    }
    if (actionTitle === 'tc-sawfphy-submit') {
      res = isInGroup('sawfphy_tc_submit', g)
    }
    if (actionTitle === 'tm-sawfphy') {
      res = isInGroup('sawfphy_tmtc_read', g)
    }

    // BBOP
    if (actionTitle === 'tc-bbop') {
      res = isInGroup('bbop_tmtc_read', g)
    }
    if (actionTitle === 'tc-bbop-submit') {
      res = isInGroup('bbop_tc_submit', g)
    }
    if (actionTitle === 'tm-bbop') {
      res = isInGroup('bbop_tmtc_read', g)
    }

    // PICOSDLA
    if (actionTitle === 'tc-picosdla') {
      res = isInGroup('picosdla_tmtc_read', g)
    }
    if (actionTitle === 'tc-picosdla-submit') {
      res = isInGroup('picosdla_tc_submit', g)
    }
    if (actionTitle === 'tm-picosdla') {
      res = isInGroup('picosdla_tmtc_read', g)
    }

    // FLOATS
    if (actionTitle === 'tc-floats') {
      res = isInGroup('floats_tmtc_read', g)
    }
    if (actionTitle === 'tc-floats-submit') {
      res = isInGroup('floats_tc_submit', g)
    }
    if (actionTitle === 'tm-floats') {
      res = isInGroup('floats_tmtc_read', g)
    }

    // LPC
    if (actionTitle === 'tc-lpc') {
      res = isInGroup('lpc_tmtc_read', g)
    }
    if (actionTitle === 'tc-lpc-submit') {
      res = isInGroup('lpc_tc_submit', g)
    }
    if (actionTitle === 'tm-lpc') {
      res = isInGroup('lpc_tmtc_read', g)
    }

    // RACHUTS
    if (actionTitle === 'tc-rachuts') {
      res = isInGroup('rachuts_tmtc_read', g)
    }
    if (actionTitle === 'tc-rachuts-submit') {
      res = isInGroup('rachuts_tc_submit', g)
    }
    if (actionTitle === 'tm-rachuts') {
      res = isInGroup('rachuts_tmtc_read', g)
    }

    // BOLDAIR
    if (actionTitle === 'tc-boldair') {
      res = isInGroup('boldair_tmtc_read', g)
    }
    if (actionTitle === 'tc-boldair-submit') {
      res = isInGroup('boldair_tc_submit', g)
    }
    if (actionTitle === 'tm-boldair') {
      res = isInGroup('boldair_tmtc_read', g)
    }

    // ROC
    if (actionTitle === 'tc-roc') {
      res = isInGroup('roc_tmtc_read', g)
    }
    if (actionTitle === 'tc-roc-submit') {
      res = isInGroup('roc_tc_submit', g)
    }
    if (actionTitle === 'tm-roc') {
      res = isInGroup('roc_tmtc_read', g)
    }

    // BECOOL
    if (actionTitle === 'tc-becool') {
      res = isInGroup('becool_tmtc_read', g)
    }
    if (actionTitle === 'tc-becool-submit') {
      res = isInGroup('becool_tc_submit', g)
    }
    if (actionTitle === 'tm-becool') {
      res = isInGroup('becool_tmtc_read', g)
    }

    // BIS
    if (actionTitle === 'tc-bis') {
      res = isInGroup('bis_tmtc_read', g)
    }
    if (actionTitle === 'tc-bis-submit') {
      res = isInGroup('bis_tc_submit', g)
    }
    if (actionTitle === 'tm-bis') {
      res = isInGroup('bis_tmtc_read', g)
    }

    // XSTORM
    if (actionTitle === 'tc-xstorm') {
      res = isInGroup('xstorm_tmtc_read', g)
    }
    if (actionTitle === 'tc-xstorm-submit') {
      res = isInGroup('xstorm_tc_submit', g)
    }
    if (actionTitle === 'tm-xstorm') {
      res = isInGroup('xstorm_tmtc_read', g)
    }

    // VATA
    if (actionTitle === 'tc-vata') {
      res = isInGroup('vata_tmtc_read', g)
    }
    if (actionTitle === 'tc-vata-submit') {
      res = isInGroup('vata_tc_submit', g)
    }
    if (actionTitle === 'tm-vata') {
      res = isInGroup('vata_tmtc_read', g)
    }


    // RIP
    if (actionTitle === 'tc-rip') {
      res = isInGroup('rip_tmtc_read', g)
    }
    if (actionTitle === 'tc-rip-submit') {
      res = isInGroup('rip_tc_submit', g)
    }
    if (actionTitle === 'tm-rip') {
      res = isInGroup('rip_tmtc_read', g)
    }
  }
  return res
}

export function getRightInstrument (actionTitle) {
  let res = false
  if (sessionStorage.getItem('groups')) {
    let groups = sessionStorage.getItem('groups')

    var g = JSON.parse(groups)

    switch (actionTitle) {
      case 'LOAC':
        res = isPartInGroup('loac', g)
        break
      case 'SAWFPHY':
        res = isPartInGroup('sawfphy', g)
        break
      case 'BBOP':
        res = isPartInGroup('bbop', g)
        break
      case 'PICOSDLA':
        res = isPartInGroup('picos', g)
        break
      case 'FLOATS':
        res = isPartInGroup('floats', g)
        break
      case 'LPC':
        res = isPartInGroup('lpc', g)
        break
      case 'RACHUTS':
        res = isPartInGroup('rachuts', g)
        break
      case 'BOLDAIR':
        res = isPartInGroup('boldair', g)
        break
      case 'ROC':
        res = isPartInGroup('roc', g)
        break
      case 'BECOOL':
        res = isPartInGroup('becool', g)
        break
      case 'XSTORM':
        res = isPartInGroup('xstorm', g)
        break
      case 'BIS':
        res = isPartInGroup('bis', g)
        break
      case 'VATA':
        res = isPartInGroup('vata', g)
        break
      case 'RIP':
        res = isPartInGroup('rip', g)
        break
    }
  }
  return res
}
