<template>
  <div id="app" data-app="true">
    <div class="content">

    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand icon-stl-nav" href="/"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <router-link to="/" class="nav-link"><span class="glyphicon glyphicon-home"></span></router-link>
          </li>
          <!--
          <li class="nav-item">
            <router-link to="/about" class="nav-link">About</router-link>
          </li>-->
          <li class="nav-item" v-if="authenticated">
            <a href="https://observations.ipsl.fr/aeris/s2dac/" class="nav-link" target="_blank">S2DAC</a>
          </li>
          <li class="nav-item" v-if="authenticated">
            <a href="https://strateole2.slack.com/" class="nav-link" target="_blank">Slack</a>
          </li>
          <li class="nav-item" v-if="authenticated">
            <a href="https://cloud.ipsl.fr/index.php/s/WECSA4YFX8FLZ4A" class="nav-link" target="_blank">SharePoint</a>
          </li>
          <li class="nav-item" v-if="suiviNacelle">
            <a href="https://docs.google.com/spreadsheets/d/1SPKxxgc2ZlVTBFN644KmD2X237HlLQhqapzkXacIjPA" class="nav-link" target="_blank">Suivi Zéphyr</a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" v-if="authenticated" to="/login" v-on:click.native="logout()" replace>Logout</router-link>
            <router-link class="nav-link" v-else to="/login" replace>Login</router-link>
          </li>
        </ul>
        <span v-if="authenticated" style="color:white" ><strong>{{this.username}}</strong>&nbsp;&nbsp;<span class="glyphicon glyphicon-user"></span></span>
      </div>
    </nav>

    <router-view @authenticated="setAuthenticated" />

  </div>
    <footer class="footer">
      <div>
        <a href="http://www.lmd.jussieu.fr" target="_blank">LMD</a> -
        <a href="https://www.latmos.ipsl.fr/" target="_blank">LATMOS</a> -
        <a href="https://www.ipsl.fr/" target="_blank">IPSL</a> -
        <a href="https://www.polytechnique.edu" target="_blank">&Eacute;COLE POLYTECHNIQUE</a> -
        <a href="http://www.dt.insu.cnrs.fr/" target="_blank">DT-INSU</a> -
        <a href="https://www.univ-reims.fr/presentation/gsma-umr-cnrs-7331,9558,17474.html" target="_blank">GSMA</a> -
        <a href="https://www.lpc2e.cnrs.fr" target="_blank">LPC2E</a> -
        <a href="https://www.umr-cnrm.fr" target="_blank">Météo-France/CNRM</a> -
        <a href="https://lasp.colorado.edu" target="_blank">LASP</a> -
        <a href="https://www.esrl.noaa.gov" target="_blank">NOAA/ESRL</a> -
        <a href="https://www.nwra.com" target="_blank">NWRA</a> -
        <a href="https://scripps.ucsd.edu" target="_blank">SIO</a> -
        <a href="https://cnes.fr" target="_blank">CNES</a> -
        <a href="https://www.insu.cnrs.fr" target="_blank">CNRS/INSU </a> -
        <a href="https://www.nsf.gov" target="_blank">NSF</a>

      </div>
    </footer>
  </div>
</template>

<script>
import { getRight } from '@/helpers.js'

export default {
  name: 'App',
  data () {
    return {
      authenticated: false,
      suiviNacelle: false,
      csrftoken: '',
      username: ''
    }
  },
  mounted () {
    if (sessionStorage.getItem('user-authenticated') && sessionStorage.getItem('csrftoken') && sessionStorage.getItem('username')) {
      this.authenticated = true
      this.username = sessionStorage.getItem('username')
    }

    if (!this.authenticated && this.$route.name !== 'home' && this.$route.name !== 'about' && this.$route.name !== 'login' && this.$route.name !== '3d') {
      this.$router.replace({ name: 'login' })
    }

    this.suiviNacelle = getRight('suivi_nacelle')
  },
  updated () {
    if (sessionStorage.getItem('user-authenticated') && sessionStorage.getItem('csrftoken') && sessionStorage.getItem('username')) { this.authenticated = true }

    if (!this.authenticated && this.$route.name !== 'home' && this.$route.name !== 'about' && this.$route.name !== 'login' && this.$route.name !== '3d') {
      this.$router.replace({ name: 'login' })
    }

    this.suiviNacelle = getRight('suivi_nacelle')
  },
  methods: {
    logout () {
      sessionStorage.removeItem('user-authenticated')
      sessionStorage.removeItem('csrftoken', '')
      sessionStorage.removeItem('username', '')
      sessionStorage.removeItem('groups')
      this.authenticated = false
      this.suiviNacelle = false
      this.username = ''
      this.csrftoken = ''
    },
    setAuthenticated (status, csrftoken, username) {
      if (status && csrftoken !== '') {
        sessionStorage.setItem('user-authenticated', true)
        sessionStorage.setItem('csrftoken', csrftoken)
        sessionStorage.setItem('username', username)
        this.authenticated = status
        this.csrftoken = csrftoken
        this.username = username
        this.$router.replace({ name: 'home' })
      } else { this.logout() }
    }
  }
}
</script>

<style>
html, body {
  height: 100%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2d6aab;

  display: flex;
  flex-direction: column;
  height: 100%;
  }
.content {
  flex: 1 0 auto;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  padding: 0px;
}
  .footer {
  flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  padding: 10px;
}
</style>
